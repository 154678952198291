import React from "react";
import "./Home.css";
import Izunwaonu from "../../assets/images/izunwaonu.jpg";


const Home = ()=> {
    return(
       <section id="home">
        <div className="home-text" data-aos="fade-down">
            <strong> I'm</strong>
            <h1>Pamela Nneoma Ojobor</h1>
            <p> 
            Highly efficient and detail-oriented virtual assistant with four years of experience in providing 
administrative support to executives and businesses. A resourceful problem solver with excellent 
communication and organizational skills, adept at managing multiple tasks and projects 
simultaneously. Seeking a challenging position as virtual assistant to contribute to the success of a dynamic organization.
            </p>
            <a href="#summery" className="btn-link">Scroll Down</a>

        </div>
        <div className="home-img" data-aos="fade-up">
            <div className="img-box">
                <img src={Izunwaonu} alt="Pamela"/>
                <h2>Pamela Nneoma Ojobor <br/> <span>Pamela's Diary</span> <br/><span> Front End Developer </span></h2>
                <div className="social">
                    <a href="https://www.facebook.com/izunwonu">
                    <i className="fa-brands fa-facebook"></i>
                    </a>
                    <a href="https://www.linkedin.com/in/pamela-ojobor-a67108232/">
                    <i className="fa-brands fa-linkedin"></i>
                    </a>
                    <a href="https://github.com/Pamelasdiary">
                    <i className="fa-brands fa-github"></i>
                    </a>

                </div>
                <a href="pamelanneoma4@gmail.com" className="hire-me">Hire me</a>

            </div>
            
        </div>

       </section>


    )
}
export default Home;