import React from "react";
import "./About.css";


const About = ()=>{
    return(
        <section id="about"> 
        <div className="about-container">
            <div className="about-heading" data-aos="flip-left">
                <strong> About me</strong>
                <h3> Designing with Passion for Problem solving </h3>
                <a href="tel:+2348068071747" className="btn-link">
                +2348068071747 <i className="fa-solid fa-phone-flip"></i>
                </a>
            </div> 
            <div className="about-details" data-aos="flip-right"> 
            <p> 
                Highly efficient and detail-oriented virtual assistant, WordPres Designing, Data Analyst and Social Media Manager with four years of experience in providing 
                administrative support to executives and businesses. A resourceful problem solver with excellent 
                communication and organizational skills, adept at managing multiple tasks and projects 
                simultaneously. Seeking a challenging position as virtual assistant to contribute to the success of a dynamic organization.
            </p>
            
            </div>

        </div>

        </section>
    )
}
export default About;